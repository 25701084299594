import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto text-center py-20">
      <h1 className="text-3xl font-bold">About Us</h1>
      <p className="mt-4">This is the about page.</p>
    </div>
  );
};

export default About;
